<template>
  <div>
    <Navigation />
    {{ settings }}
    <ElInput v-model="settings['profitMargin']" />
    <ElButton @click="save">Salveaza</ElButton>
  </div>
</template>

<script>
export default {
  data() {
    return {
      settings: {}
    };
  },
  async created() {
    const { data } = await this.$fetch(this.$firestore().doc("settings/main"));
    this.settings = data || {};
  },
  methods: {
    async save() {
      try {
        await this.$firestore()
          .doc("settings/main")
          .update(this.settings);
        this.$message.success("Modificarea a fost efectuata.");
      } catch (error) {
        this.$message.error("Oops, a intervenit o eroare.");
      }
    }
  }
};
</script>
